import { render, staticRenderFns } from "./2.vue?vue&type=template&id=f723f46a&scoped=true&"
import script from "./2.vue?vue&type=script&lang=js&"
export * from "./2.vue?vue&type=script&lang=js&"
import style0 from "./2.vue?vue&type=style&index=0&id=f723f46a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f723f46a",
  null
  
)

export default component.exports